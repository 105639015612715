import axios from "axios";
import { Project } from "../model/Project";


export const getProjects = async () => {
    const projects = await axios.get<Project[]>('/projects')
    return projects.data
}

export const getProject = async (project_id: string) => {
    const project = await axios.get<Project>(`/projects/${project_id}`);
    return project.data;
}

export const addProject = (project: Omit<Project, "id">) => {
    return axios.post('/projects', project)
}

export const updateProject = async (id: string, name: string, description: string, image: string) => {
    return await axios.patch(`/projects/${id}`, {
        name: name,
        description: description,
        image: image,
    })
}

export const deleteProject = (id: string) => {
    return axios.delete(`/projects/${id}`)
}
