import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material"
import { Project } from "../model/Project"
import { useState } from "react"
import { updateProject } from "../services/ProjectDataService"

interface EditDialogProps {
    isOpen: boolean
    project: Project
    onClose: () => void
}

export default function EditDialog({ isOpen, project, onClose }: EditDialogProps) {

    const [formInputName, setFormInputName] = useState(project.name)
    const [formInputDescription, setFormInputDescription] = useState(project.description)
    const [formInputImage, setFormInputImage] = useState(project.image)

    const update = () => {
        updateProject(project.id, formInputName, formInputDescription, formInputImage)
        onClose()
        window.location.reload();
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <form>
                <DialogTitle>Edit product</DialogTitle>
                <DialogContentText paddingX={3}>
                    Edit product details
                </DialogContentText>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField style={{ paddingBottom: 5, width: "100%" }} label="Name" value={formInputName} onChange={(e) => setFormInputName(e.target.value)} />
                        <TextField style={{ paddingBottom: 5, width: "100%" }} label="Description" value={formInputDescription} onChange={(e) => setFormInputDescription(e.target.value)} />
                        <TextField style={{ paddingBottom: 5, width: "100%" }} label="Image" value={formInputImage} onChange={(e) => setFormInputImage(e.target.value)} />
                    </div>
                </DialogContent>
                <DialogActions
                    style={{ paddingRight: "1.5em", paddingBottom: "1.5em" }}
                >
                    <Button onClick={() => update()} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}