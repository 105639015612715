import axios from "axios";
import { Task } from "../model/Task";
import { Position } from "../model/Position";


export const getTasks = async (id: string) => {
    const tasks = await axios.get<Task[]>(`/projects/${id}/tasks`)
    return tasks.data
}

export const addTask = (task: Omit<Task, "id" | "project">, projectId:string) => {
    return axios.post(`/projects/${projectId}/tasks/`, task)
}


export const updateTask = async (id: string, name: string, description: string, completed: Boolean, priority:string, dueDate:string, position:Position) => {
    return await axios.patch(`/tasks/${id}`, {
        name: name,
        description: description,
        completed: completed,
        priority: priority,
        dueDate: dueDate,
        position: position
    })
}

export const deleteTask = (id: string) => {
    return axios.delete(`/tasks/${id}`)
}
