import { useTasks } from "../hooks/useTasks";
import { useProject } from "../hooks/useProject";
import { useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import './Tasks.css';
import CardMedia from '@mui/material/CardMedia';
import TaskIconImage from '../images/location_symbol.png';
import { useState, useEffect, useContext } from 'react';
import Switch from '@mui/material/Switch';

import { Search } from './Search';
import { Filter, FilterOptions } from "./Filter";
import { Task, TaskData } from "../model/Task";
import { Button, IconButton } from "@mui/material";

import ManagerContext, { IManagerContext } from '../context/ManagerContext';
import AddTaskDialog from "./AddTaskDialog";
import { useTaskMutation } from "../hooks/useTaskMutation";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditDialogTask from "./EditDialogTask";
import DeleteDialog from "./DeleteDialogTask";

export function Tasks() {
  const { project_id } = useParams<{ project_id: string }>();
  const { isLoading, isError, tasks } = useTasks(project_id!);
  const { project } = useProject(project_id!);
  const [currentView, setCurrentView] = useState(localStorage.getItem('currentView') || 'image');
  const [filteredTasks, setFilteredTasks] = useState<Task[]>(tasks || []);
  const { isManager, toggleManager } = useContext<IManagerContext>(ManagerContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { addTaskMutation } = useTaskMutation(project_id!)

  const [addTaskPosition, setAddTaskPosition] = useState<{ x: number; y: number } | null>(null);

  const addTask = (data: TaskData) => {
    addTaskMutation(data)
  };

  const toggleView = () => {
    const newView = currentView === 'image' ? 'table' : 'image';
    setCurrentView(newView);
    localStorage.setItem('currentView', newView);
  };

  // So the list gets populated after first page load
  useEffect(() => {
    setFilteredTasks(tasks || []);
  }, [tasks]);

  useEffect(() => {
    localStorage.setItem('currentView', currentView);
  }, [currentView]);

  const handleSearchResults = (filteredData: Task[]) => {
    setFilteredTasks(filteredData);
  };

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    // Load the image to get its dimensions
    if (project && project.image) {
      const img = new Image();
      img.src = project.image;
      img.onload = () => {
        setImageWidth(img.naturalWidth);
        setImageHeight(img.naturalHeight);

        console.log(`Image Width for ${project.name}:`, img.naturalWidth);
        console.log(`Image Height for ${project.name}:`, img.naturalHeight);
      };
    }
  }, [project]);


  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="TasksContainer"
      >
        <CircularProgress size="10vh" />
      </Grid>
    );
  }

  if (isError || !tasks) {
    return (
      <Alert variant="filled" severity="error">
        Can't connect with the server!
      </Alert>
    );
  }


  // Calculate the percentage of completed tasks
  const completedTasksCount = tasks.filter((task) => task.completed).length;
  const totalTasksCount = tasks.length;
  const progressPercentage = (completedTasksCount / totalTasksCount) * 100;

  const handleAddTask = () => {
    setIsDialogOpen(true)
  }

  const handleEditTask = (task: Task) => {
    setSelectedTask(task);
    setIsEditDialogOpen(true);
  };

  const handleDeleteTask = (task: Task) => {
    setSelectedTask(task);
    setIsDeleteDialogOpen(true);
  };


  return (
    <>
      <div className="TasksContainer">
        <h1>Tasks</h1>
        {project && (
          <div className="TableImageContainer">
            {currentView === 'image' && (
              <>
                <CardMedia
                  component="img"
                  image={project.image}
                  alt={project.name}
                  className="TaskImage"
                  onClick={(e) => {
                    if (isManager) {
                      const rect = e.currentTarget.getBoundingClientRect();
                      const x = (e.clientX - rect.left) / rect.width;
                      const y = (e.clientY - rect.top) / rect.height;
                      console.log("Click Coordinates:", x, y);
                      setAddTaskPosition({ x, y });
                      setIsDialogOpen(true);
                    }
                  }}
                />
                {tasks.map((task) => {
                  const left = task.position.x * imageWidth;
                  const top = task.position.y * imageHeight;

                  return (
                    <div
                      key={task.id}
                      style={{
                        position: 'absolute',
                        left: `${left}px`,
                        top: `${top}px`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                      }}
                      onClick={() => {
                        if (isManager) {
                          setSelectedTask(task);
                          setIsEditDialogOpen(true);
                        }
                      }}
                    >
                      <img src={TaskIconImage} alt="Task Icon" className="TaskIcon" />
                    </div>
                  );
                })}
              </>
            )}

            {currentView === 'table' && (
              <div style={{ textAlign: 'center' }}>

                {isManager ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTask}
                  >
                    Add Task
                  </Button>
                ) : null}

                <div>

                  <div className="SearchContainer">
                    <Search
                      data={tasks || []}
                      filterKey="name"
                      onSearch={handleSearchResults}
                    />
                  </div>

                  <div className="FilterContainer">
                    <Filter tasks={tasks} onFilter={(filteredData) => setFilteredTasks(filteredData)} />
                  </div>

                  <TableContainer component={Paper}>
                    <Table className="SearchResultsTable" aria-label="search results table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="TableHeaderCell">Name</TableCell>
                          <TableCell className="TableHeaderCell" align="right">Description</TableCell>
                          <TableCell className="TableHeaderCell" align="right">Completed</TableCell>
                          <TableCell className="TableHeaderCell" align="right">Priority</TableCell>
                          <TableCell className="TableHeaderCell" align="right">Due Date</TableCell>
                          {isManager && (
                            <TableCell className="TableHeaderCell" align="right">Position</TableCell>
                          )}
                          {isManager && (
                            <TableCell className="TableHeaderCell" align="right">Edit / Delete</TableCell>
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filteredTasks.map((task) => (
                          <TableRow key={task.id} className="TableRow">
                            <TableCell component="th" scope="row">
                              {task.name}
                            </TableCell>
                            <TableCell align="right">{task.description}</TableCell>
                            <TableCell align="right">{task.completed ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="right">{task.priority}</TableCell>
                            <TableCell align="right" colSpan={1}>{task.dueDate}</TableCell>
                            {isManager && (
                              <TableCell align="right" colSpan={isManager ? 1 : 2}>
                                ({task.position.x}, {task.position.y})
                              </TableCell>
                            )}
                            {isManager && (
                              <TableCell align="right">
                                <IconButton onClick={() => handleEditTask(task)} aria-label="edit">
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteTask(task)} aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>

                        ))}

                      </TableBody>

                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="ProgressContainer">
          <div className="ProgressLabel">{`${progressPercentage.toFixed(0)}% Completed`}</div>
          <LinearProgress variant="determinate" value={progressPercentage} className="Progress" />
        </div>

        <div className="ToggleViewContainer">
          <div className="TableSwitchLabel">
            {currentView === 'image' ? 'Switch to Table View' : 'Switch to Image View'}
          </div>
          <Switch
            checked={currentView === 'table'}
            onChange={toggleView}
            color="primary"
            inputProps={{ 'aria-label': 'toggle between image and table view' }}
          />
        </div>
      </div>

      <AddTaskDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setAddTaskPosition(null);
        }}
        onSubmit={(data) => {
          if (addTaskPosition) {
            data.position = addTaskPosition;
          }
          addTaskMutation(data);
          setAddTaskPosition(null);
        }}
        onPositionSelect={(position) => setAddTaskPosition(position)}
        addTaskPosition={addTaskPosition || undefined}
      />

      {selectedTask && (
        <EditDialogTask
          isOpen={isEditDialogOpen}
          task={selectedTask}
          onClose={() => {
            setIsEditDialogOpen(false);
            setSelectedTask(null);
          }}
        />
      )}

      {selectedTask && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          task={selectedTask}
          onClose={() => {
            setIsDeleteDialogOpen(false);
            setSelectedTask(null);
          }}
        />
      )}
    </>
  );
};