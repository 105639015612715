import { ChangeEvent, useState } from 'react';

interface SearchProps<T> {
  data: T[];
  filterKey: keyof T;
  onSearch: (results: T[]) => void;
}

export function Search<T>({ data, filterKey, onSearch }: SearchProps<T>) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = data.filter((item) =>
      String(item[filterKey]).toLowerCase().includes(term.toLowerCase())
    );
    onSearch(filtered);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        style={{
          width: '100%',
          padding: '10px',
          fontSize: '16px',
          border: 'none',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          outline: 'none',
          transition: 'box-shadow 0.2s ease',
          boxSizing: 'border-box',
        }}
      />
    </div>
  );
}