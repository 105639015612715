import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { CardActionArea, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import EditDialog from './EditDialogProject'
import { Project } from "../model/Project"
import DeleteDialog from './DeleteDialogProject'

interface ProjectCardProps {
  project:Project
  isManager: boolean;
}

export function ProjectCard({ project, isManager }: ProjectCardProps) {
  const navigate = useNavigate();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <div style={{ paddingRight: 30, paddingTop: 50 }} className='project' >
      <Card onClick={() => navigate(`/projects/${project.id}/tasks`)} sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="200"
            image={project.image}
            alt={project.name}
            sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {project.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {project.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <CardActions>
            {isManager && (
              <>

                <Button onClick={() => setIsEditDialogOpen(true)} size="small" variant="outlined">
                  Edit
                </Button>

                <Button onClick={() => setIsDeleteDialogOpen(true)} size="small" color="error" variant="outlined">
                  Delete
                </Button>
              </>
            )}
          </CardActions>

      <EditDialog isOpen={isEditDialogOpen} project={project} onClose={() => setIsEditDialogOpen(false)} />
      <DeleteDialog isOpen={isDeleteDialogOpen} project={project} onClose={() => setIsDeleteDialogOpen(false)} />
    </div>
  );


}

export default ProjectCard;