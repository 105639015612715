import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { TaskData } from "../model/Task";
import { useState } from "react";


interface AddTaskProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: TaskData) => void
  onPositionSelect: (position: { x: number; y: number }) => void;
  addTaskPosition?: { x: number; y: number } | undefined;
}

const initialState = {
  name: "",
  description: "",
  completed: false,
  priority: "",
  dueDate: "08/28/2023",
  position: { x: 0, y: 0 }
}


const REQUIRED_FIELD_MESSAGE = "This field is required";

const MIN_LENGHT_MESSAGE = (length: number) =>
  `Please enter minimum ${length} characters.`;

export default function AddTaskDialog({ isOpen, onClose, onSubmit, onPositionSelect, addTaskPosition }: AddTaskProps) {

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      ...initialState,
      position: addTaskPosition || initialState.position,
    }
  });

  const [selectedDueDate, setSelectedDueDate] = useState(null);


  const _onSubmit = (data: TaskData) => {
    onSubmit(data);
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(_onSubmit)}>
        <DialogTitle>Add Task</DialogTitle>
        <DialogContentText paddingX={3}>
          Add Task details
        </DialogContentText>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column" }}>

            <Controller
              name="name"
              control={control}
              rules={{
                required: REQUIRED_FIELD_MESSAGE,
                minLength: { value: 3, message: MIN_LENGHT_MESSAGE(3) },
              }}
              render={({ field }) => (
                <TextField
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Name"
                  {...field}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Description"
                  {...field}
                />
              )}
            />

            <FormControlLabel
              style={{ paddingBottom: 30 }}
              control={
                <Controller
                  name="completed"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Completed"
            />

            <FormControl style={{ width: '100%', paddingBottom: 30 }} error={!!errors.priority}>
              <InputLabel id="priority-label">Priority</InputLabel>
              <Controller
                name="priority"
                control={control}
                rules={{
                  required: REQUIRED_FIELD_MESSAGE,
                }}
                render={({ field }) => (
                  <>
                    <Select
                      labelId="priority-label"
                      value={field.value}
                      label="Priority"
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                    </Select>
                    {errors.priority && (
                      <FormHelperText>{REQUIRED_FIELD_MESSAGE}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>

            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  defaultValue="08/28/2023"
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Due Date"
                  {...field}
                />
              )}
            />

          </div>
        </DialogContent>
        <DialogActions
          style={{ paddingRight: "1.5em", paddingBottom: "1.5em" }}
        >
          <Button type="reset" variant="outlined" onClick={() => reset()}>
            Clear
          </Button>
          <Button type="submit" variant="contained">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}