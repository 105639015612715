import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Project } from '../model/Project'
import {deleteProject} from "../services/ProjectDataService";

interface DeleteDialogProps {
    isOpen: boolean;
    project: Project;
    onClose: () => void;
}

export default function DeleteDialog({ isOpen, project, onClose }: DeleteDialogProps) {

    const deleteProductHandler = () => {
        deleteProject(project.id)
        onClose()
        window.location.reload();
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>Delete project?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting a project will remove it permanently!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => deleteProductHandler()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}