import './App.css';
import './components/DarkLightMode.css'
import { Projects } from './components/Projects';
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Tasks } from './components/Tasks';
import LoginManager from './components/LoginManager';
import ManagerContextProvider from './context/ManagerContextProvider';
import DarkModeContextProvider from './context/DarkModeContextProvider';
import DarkModeToggleButton from './components/DarkModeToggleButton';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useContext } from 'react';
import DarkModeContext from './context/DarkModeContext';



axios.defaults.baseURL = "http://localhost:3001";
const queryClient = new QueryClient();
  

function App() { 
  const { isDarkModeEnabled } = useContext(DarkModeContext);

  const theme = createTheme({
    palette: {
      mode: isDarkModeEnabled ? 'dark' : 'light'
    },
  });
  
  return (
    <>
      <QueryClientProvider client={queryClient}>
      <ManagerContextProvider>
        <DarkModeContextProvider>
          <BrowserRouter>
          <ThemeProvider theme={theme}>
          <LoginManager />
          <DarkModeToggleButton />
            <Routes>
              <Route path="/" element={<Projects />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:project_id/tasks" element={<Tasks />} />
            </Routes>
            </ThemeProvider>
          </BrowserRouter>
          </DarkModeContextProvider>
          </ManagerContextProvider>
      </QueryClientProvider>
    </>
  )
}

export default App;
