import { FormEvent, useState, useEffect } from 'react';
import { Task } from '../model/Task';
import './Filter.css';

interface FilterProps {
    tasks: Task[];
    onFilter: (filteredData: Task[]) => void;
}

export interface FilterOptions {
    completed: string | 'all';
    priority: string | 'all';
    dueDate: string | 'all';
}

export function Filter({ tasks, onFilter }: FilterProps) {
    const [completed, setCompleted] = useState<string | 'all'>('all');
    const [priority, setPriority] = useState<string | 'all'>('all');
    const [dueDate, setDueDate] = useState<string | 'all'>('all');
    const [filteredData, setFilteredData] = useState<Task[]>(tasks);

    useEffect(() => {
        applyFilters();
    }, [tasks, completed, priority, dueDate]);

    const applyFilters = () => {
        let filteredData = tasks.slice();

        if (completed !== 'all') {
            const completedValue: boolean = completed === 'true';
            filteredData = filteredData.filter((task) => task.completed === completedValue);
        }

        if (priority !== 'all') {
            filteredData = filteredData.filter((task) => task.priority === priority);
        }

        if (dueDate !== 'all') {
            const currentDate = new Date();
            const dueDateFilter = parseInt(dueDate);
            filteredData = filteredData.filter((task) => {
                const taskDueDate = new Date(task.dueDate);
                const timeDiff = taskDueDate.getTime() - currentDate.getTime();
                const daysDiff = timeDiff / (1000 * 3600 * 24);
                return daysDiff <= dueDateFilter && daysDiff >= 0;
            });
        }

        setFilteredData(filteredData);
        onFilter(filteredData);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        applyFilters();
    };

    const handleReset = () => {
        setCompleted('all');
        setPriority('all');
        setDueDate('all');
    };

    return (
        <form onSubmit={handleSubmit} className="filter-form">
          <div className="filter-item">
            <label htmlFor="completed">Completed:</label>
            <select
              id="completed"
              value={completed}
              onChange={(e) => setCompleted(e.target.value as string | 'all')}
            >
              <option value="all">All</option>
              <option value="true">Completed</option>
              <option value="false">Not Completed</option>
            </select>
          </div>
    
          <div className="filter-item">
            <label htmlFor="priority">Priority:</label>
            <select
              id="priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option value="all">All</option>
              <option value="high">High</option>
              <option value="normal">Normal</option>
              <option value="low">Low</option>
            </select>
          </div>
    
          <div className="filter-item">
            <label htmlFor="dueDate">Due Date (in days):</label>
            <input
              type="number"
              id="dueDate"
              value={dueDate === 'all' ? '' : dueDate}
              min={1}
              onChange={(e) =>
                setDueDate(e.target.value === '' ? 'all' : e.target.value)
              }
            />
          </div>
    
          <div className="filter-item">
            <button type="button" onClick={handleReset}>
              Reset
            </button>
          </div>
        </form>
      );
    }