import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ProjectData } from "../model/Project";

interface AddProjectProps {
    isOpen: boolean
    onClose: () => void
    onSubmit: (data: ProjectData) => void
}

const initialState = {
    name: "",
    description: "",
    image: ""
}

const REQUIRED_FIELD_MESSAGE = "This field is required";

const MIN_LENGHT_MESSAGE = (length: number) =>
    `Please enter minimum ${length} characters.`;

export default function AddProductDialog({ isOpen, onClose, onSubmit }: AddProjectProps) {

    const { control, handleSubmit, reset, formState: { errors } } = useForm({ defaultValues: initialState },)

    const _onSubmit = (data: ProjectData) => {
        onSubmit(data);
        reset();
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <form onSubmit={handleSubmit(_onSubmit)}>
                <DialogTitle>Add Project</DialogTitle>
                <DialogContentText paddingX={3}>
                    Add Project details
                </DialogContentText>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>

                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: REQUIRED_FIELD_MESSAGE,
                                minLength: { value: 3, message: MIN_LENGHT_MESSAGE(3) },
                            }}
                            render={({ field }) => (
                                <TextField
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                    style={{ paddingBottom: 5, width: "100%" }}
                                    label="Name"
                                    {...field}
                                />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={!!errors.name}
                                    helperText={errors.description?.message}
                                    style={{ paddingBottom: 5, width: "100%" }}
                                    label="Description"
                                    {...field}
                                />
                            )}
                        />

                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={!!errors.name}
                                    helperText={errors.image?.message}
                                    style={{ paddingBottom: 5, width: "100%" }}
                                    label="Image"
                                    {...field}
                                />
                            )}
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    style={{ paddingRight: "1.5em", paddingBottom: "1.5em" }}
                >
                    <Button type="reset" variant="outlined" onClick={() => reset()}>
                        Clear
                    </Button>
                    <Button type="submit" variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}