import React, { useEffect } from "react";
import DarkModeContext from "./DarkModeContext";
import useLocalStorage from "../hooks/useLocalStorage";

interface IDarkModeContextProviderProps {
  children: React.ReactNode;
}

export default function DarkModeContextProvider({children}: IDarkModeContextProviderProps) {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useLocalStorage("isDarkModeEnabled")

  const toggleDarkMode = () => setIsDarkModeEnabled(!isDarkModeEnabled)

  useEffect(() => {
    const body = document.body;
    if (isDarkModeEnabled) {
      body.classList.add("dark-mode");
    } else {
      body.classList.remove("dark-mode");
    }
  }, [isDarkModeEnabled]);

  
  return (
    <DarkModeContext.Provider value={{isDarkModeEnabled, toggleDarkMode}}>
      {children}
    </DarkModeContext.Provider>
  );
};