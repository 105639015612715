import {useMutation, useQueryClient} from "@tanstack/react-query";
import { ProjectData } from "../model/Project";
import {addProject} from "../services/ProjectDataService";

export function useProjectMutation() {
    const queryClient = useQueryClient();
    const {
        mutate: addProjectMutation,
        isLoading: isAddingProject,
        isError: isErrorAddingProject,
    } = useMutation(
        (project: ProjectData) => addProject(project),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["projects"])
            },
        }
    );

    return {
        addProjectMutation,
        isAddingProject,
        isErrorAddingProject,
    };
}