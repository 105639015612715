import {useMutation, useQueryClient} from "@tanstack/react-query";
import { Task, TaskData } from "../model/Task";
import {addTask} from "../services/TaskDataService";

export function useTaskMutation(projectId:string) {
    const queryClient = useQueryClient();
    const {
        mutate: addTaskMutation,
        isLoading: isAddingTask,
        isError: isErrorAddingTask,
    } = useMutation(
        (task: Omit<Task, "id" | "project">) => addTask(task, projectId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["tasks"])
            },
        }
    );

    return {
        addTaskMutation,
        isAddingTask,
        isErrorAddingTask,
    };
}