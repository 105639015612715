import { useProjects } from "../hooks/useProjects";
import "./Projects.css"
import {
  Alert,
  Grid,
  CircularProgress,
} from "@mui/material";
import ProjectCard from "./ProjectCard";
import AddProjectCard from "./AddProjectCard";
import { useContext, useState, useEffect } from "react";
import ManagerContext, { IManagerContext } from '../context/ManagerContext';
import AddProjectDialog from './AddProjectDialog';
import { Project, ProjectData } from "../model/Project";

import { useProjectMutation } from "../hooks/useProjectMutation";

import { Search } from './Search';


export function Projects() {

  const { isLoading, isError, projects } = useProjects();
  const { isManager, toggleManager } = useContext<IManagerContext>(ManagerContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { addProjectMutation } = useProjectMutation()

  const addProject = (data: ProjectData) => {
    addProjectMutation(data)
  };

  const [filteredProjects, setFilteredProjects] = useState<Project[]>(projects || []);

  // So the list gets populated after first page load
  useEffect(() => {
    setFilteredProjects(projects || []);
  }, [projects]);

  const handleSearchResults = (filteredData: Project[]) => {
    setFilteredProjects(filteredData);
  };


  if (isLoading) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}><CircularProgress size='10vh' /></Grid>
  }

  if (isError || !projects) {
    return <Alert variant="filled" severity="error">Can't connect with server!</Alert>
  }

  const renderprojectsfiltered = () => {
    if (typeof (filteredProjects) !== 'undefined') {
      return filteredProjects && filteredProjects.length ? filteredProjects.map((project) => (
        <ProjectCard key={project.id} project={project} isManager={isManager} />
      )) : <p>No Projects found</p>
    }
  }

  const handleAddProject = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Projects</h1>
      </div>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Search
            data={projects || []}
            filterKey="name"
            onSearch={handleSearchResults}
          />
        </div>
        <div className="projects">

          {renderprojectsfiltered()}

          {isManager ? (
            <AddProjectCard onClick={handleAddProject} />
          ) : null}

        </div>

      <AddProjectDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={addProject}
      />

    </>
  );
}