import { ReactElement } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import ManagerContext from "./ManagerContext";

interface IWithChildren {
    children: ReactElement | ReactElement[];
}

export default function ManagerContextProvider({ children }: IWithChildren) {
    const [isManager, setIsManager] = useLocalStorage("isManager");

    const toggleManager = () => setIsManager(!isManager);
    return (
        <ManagerContext.Provider value={{ isManager, toggleManager }}>
            {children}
        </ManagerContext.Provider>
    );
}