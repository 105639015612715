import { useContext } from "react";
import DarkModeContext from "../context/DarkModeContext";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";

function DarkModeToggleButton() {
  const { isDarkModeEnabled, toggleDarkMode } = useContext(DarkModeContext);

  return (
    <Box className="login-manager-container">
    <FormControlLabel
      control={
        <Switch
          checked={isDarkModeEnabled}
          onChange={toggleDarkMode}
          name="darkModeToggle"
          color="primary"
        />
      }
      label={isDarkModeEnabled ? "Switch to Light Mode" : "Switch to Dark Mode"}
    />
    </Box>
  );
}

export default DarkModeToggleButton;