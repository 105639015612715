import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Task } from '../model/Task'
import {deleteTask} from "../services/TaskDataService";

interface DeleteDialogProps {
    isOpen: boolean;
    task: Task;
    onClose: () => void;
}

export default function DeleteDialog({ isOpen, task, onClose }: DeleteDialogProps) {

    const deleteTaskHandler = () => {
        deleteTask(task.id)
        onClose()
        window.location.reload();
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>Delete task?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting a task will remove it permanently!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => deleteTaskHandler()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}