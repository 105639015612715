import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Task } from "../model/Task";
import { updateTask } from "../services/TaskDataService";

interface EditDialogProps {
  isOpen: boolean;
  task: Task;
  onClose: () => void;
}

export default function EditDialog({ isOpen, task, onClose }: EditDialogProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: task.name,
      description: task.description,
      completed: task.completed,
      priority: task.priority,
      dueDate: task.dueDate,
      position: task.position,
    },
  });

  const _onSubmit = (data: any) => {
    updateTask(
      task.id,
      data.name,
      data.description,
      data.completed,
      data.priority,
      data.dueDate,
      data.position
    );
    onClose();
    window.location.reload();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(_onSubmit)}>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContentText paddingX={3}>
          Edit task details
        </DialogContentText>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
                minLength: { value: 3, message: "Name must be at least 3 characters" },
              }}
              render={({ field }) => (
                <TextField
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Name"
                  {...field}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Description"
                  {...field}
                />
              )}
            />

            <Controller
              name="completed"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  style={{ paddingBottom: 30, width: "100%" }}
                  control={<Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />}
                  label="Completed"
                />
              )}
            />

            <FormControl style={{ width: "100%", paddingBottom: 30 }}>
              <InputLabel id="priority-label">Priority</InputLabel>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <Select labelId="priority-label" value={field.value} label="Priority" onChange={(e) => field.onChange(e.target.value)}>
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  type="date"
                  defaultValue="08/28/2023"
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Due Date"
                  {...field}
                />
              )}
            />

            <Controller
              name="position.x"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Position X"
                  {...field}
                />
              )}
            />

            <Controller
              name="position.y"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  style={{ paddingBottom: 30, width: "100%" }}
                  label="Position Y"
                  {...field}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: "1.5em", paddingBottom: "1.5em" }}>
          <Button type="reset" variant="outlined" onClick={() => reset()}>
            Clear
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}