import { createContext } from "react";

export interface IDarkModeContext {
    isDarkModeEnabled: boolean;
    toggleDarkMode: () => void;
}

export default createContext<IDarkModeContext>({
    isDarkModeEnabled: false,
    toggleDarkMode: () => {},
})