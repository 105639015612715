import {useQuery} from '@tanstack/react-query'
import { getProjects } from '../services/ProjectDataService'

export function useProjects() {
    const {
        isLoading,
        isError,
        data: projects,
    } = useQuery(["projects"],getProjects)

    return {
        isLoading,
        isError,
        projects
    }
}