import { Box, Button, TextField } from '@mui/material';
import ManagerContext, { IManagerContext } from '../context/ManagerContext';
import { useContext } from "react";
import { useState } from "react";
import "./LoginManager.css";


export function LoginManager() {

    const { isManager, toggleManager } = useContext<IManagerContext>(ManagerContext);

    const [tfValue, setTFValue] = useState("beheerder@ui3tasks.be");

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        if (tfValue === "beheerder@ui3tasks.be" || isManager) {
            toggleManager()
        }
    }

    return (
        <Box className="login-manager-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <TextField
                    className="login-textfield"
                    onChange={(newValue) => setTFValue(newValue.target.value)}
                    value={tfValue}
                    label="Email"
                    variant="outlined"
                    size="small"
                />
                {isManager ? (
                    <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        className="login-button logout-button"
                    >
                        Log Out
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="login-button login-button"
                    >
                        Login
                    </Button>
                )}
            </form>
        </Box>
    );
}

export default LoginManager;