import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { CardActionArea } from '@mui/material';

interface AddProjectCardProps {
    onClick: () => void;
}

export function AddProjectCard({ onClick }: AddProjectCardProps) {
    return (
        <div style={{ paddingRight: 30, paddingTop: 50 }} >
            <Card sx={{ maxWidth: 345 }} onClick={onClick}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="200"
                        image="/img/plus_icon.png"
                        alt="plus icon"
                        sx={{ padding: "1em 1em 1.5em 1em", objectFit: "contain" }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Add New Project
                        </Typography>

                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}

export default AddProjectCard;