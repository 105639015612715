import {useQuery} from '@tanstack/react-query'
import { getTasks } from '../services/TaskDataService'

export function useTasks(project_id: string) {
    const {
        isLoading,
        isError,
        data: tasks,
    } = useQuery(["tasks"],() => getTasks(project_id))

    return {
        isLoading,
        isError,
        tasks
    }
}