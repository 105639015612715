import { createContext } from "react";

export interface IManagerContext {
    isManager: boolean;
    toggleManager: () => void;
}

export default createContext<IManagerContext>({
    isManager: false,
    toggleManager: () => {},
})