import {useQuery} from '@tanstack/react-query'
import { getProject } from '../services/ProjectDataService'

export function useProject(project_id: string) {
    const {
        isLoading,
        isError,
        data: project,
    } = useQuery(["projects"], () => getProject(project_id))

    return {
        isLoading,
        isError,
        project
    }
}